import 'bootstrap-datepicker';
import $ from 'jquery';
import getDatePickerConfig from "JIX/jix_datepicker.js";

const stashes = window.Stash['widgets/jix_datepicker'];

stashes.forEach(({ id, stash }) => {
    const element = document.getElementById(id);
    const datepicker_options = getDatePickerConfig(element, stash);
    $(element).datepicker(datepicker_options);
});
