import { getLocale } from 'JIX/utils.js';

// Jobindex-specific sane defaults for bootstrap-datepicker
export default function getDatePickerConfig(element, options = {}) {
    // Default options; can be overridden when jix_datepicker is called.
    // Full options can be found here: https://bootstrap-datepicker.readthedocs.io
    const datepicker_options = {
        language: getLocale({ element }),
        autoclose: true,
        calendarWeeks: true,
        container: document.querySelector('main') ? 'main'
            : (document.querySelector('.jix_flow') ? '.jix_flow' : 'body'),
        orientation: "bottom",
        format: "yyyymmdd",
        todayHighlight: true,
        ...(element || { }).dataset,
        ...options,
    };

    return datepicker_options;
}
